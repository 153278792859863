<template>
  <div>
    <div class="card-toolbar mb-5">

      <router-link v-if="$can('offers.create')" :to="{name: 'offers.create'}" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('offers.add_offer') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">

            <div class="form-group col-md-6">
              <label for="name">{{ $t('offers.name') }}</label>
              <input v-model="filters.name" type="text" id="name" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="from_date">{{ $t('offers.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="to_date">{{ $t('offers.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="is_active" slot-scope="props">
            <b-form-checkbox  v-if="$can('offers.update')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon v-if="$can('offers.update')" small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')"  color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
            <v-icon v-if="$can('offers.delete')" small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>

          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-offers",
  components: {},
  data() {
    return {
      mainRoute: 'settings/offers',
      subMainRoute: 'settings/offer',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      dataList: [],

      filter: {
        sortBy: 'id',
      },
      filters: {
        name: null,
        from_date: null,
        to_date: null,
      },
      columns: ['name', 'users_number', 'start_date', 'end_date', 'is_active', 'actions'],
      data: [],

      agent: null,
      agents: [],
    }
  },
  computed: {
    // json_fields: function () {
    //   let fields = {};
    //   fields[this.$t('users.name')] = 'name';
    //   fields[this.$t('users.email')] = 'email';
    //   fields[this.$t('users.created_at')] = 'created_at';
    //
    //   return fields;
    // },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('offers.name'),
          users_number: that.$t('offers.users_number'),
          start_date: that.$t('offers.start_date'),
          end_date: that.$t('offers.end_date'),
          is_active: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },


  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.offers")}]);
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.from_date = null;
      this.filters.to_date = null;

      this.$refs.table.refresh();
    },

    getFetch() {
      this.$refs.table.refresh();
    },

    editItem(item) {
      this.$router.push({name: 'offers.update', params: {id: item.id}});
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch(({errors}) => {
        console.error(errors);
      })
    },
    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        is_active: (status ? '1' : '0'),
      }).then(response => {
        this.getFetch();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
  },
};
</script>
